import React from 'react';
import { useGetOrganizationQuery } from 'api/organizations';
import { useParams } from 'react-router';
import { useGetOrganizationAssignmentsQuery } from 'api/organizations';
import { t } from 'i18next';
import { OWNER } from 'utils/users-role';
import { groupBy } from 'utils/groupBy';
import ADDRESS_TYPE from 'utils/address-type';
import OverviewHeader from './overview-header.component';
import ModeOfCommunication from './shared/mode-of-communication.component';
import AddressOverview from './address-overview.component';
import ContactOverview from './contact-overview.component';

const OverViewComponent = () => {
  const { id } = useParams();
  const { data: organisation, isFetching } = useGetOrganizationQuery(id);
  const { data: owners } = useGetOrganizationAssignmentsQuery({ organizationId: id, role: OWNER });

  const mainAddress = organisation?.addresses?.find(address => address.main === true);
  const groupedAddresses = groupBy(organisation?.addresses || [], 'type');
  const billingAddress = groupedAddresses[ADDRESS_TYPE.invoice]?.[0];
  const mailingAddress = groupedAddresses[ADDRESS_TYPE.mailing]?.[0];
  const visitorAddress = groupedAddresses[ADDRESS_TYPE.visitor]?.[0];

  return (
    <>
      <div className="organisation-overview__card-wrapper">
        <OverviewHeader isFetching={isFetching} organisation={organisation} owners={owners} />
        <ModeOfCommunication
          organisation={organisation}
          isFetching={isFetching}
          isGastronomyView={true}
        />
        <AddressOverview
          address={mainAddress}
          type={t('organisation_view.overview.main_address')}
          isFetching={isFetching}
          isAddressPresent={mainAddress}
          emptyPlaceholderTranslationKey="organisation_view.overview.no_main_address_found"
        />
        <AddressOverview
          address={mailingAddress}
          type={t('organisation_view.overview.mailing_address')}
          isFetching={isFetching}
          isAddressPresent={mailingAddress}
          emptyPlaceholderTranslationKey="organisation_view.overview.no_mailing_address_found"
        />
        <AddressOverview
          address={billingAddress}
          type={t('organisation_view.overview.invoice_mailing_address')}
          isFetching={isFetching}
          isAddressPresent={billingAddress}
          emptyPlaceholderTranslationKey="organisation_view.overview.no_billing_address_found"
        />
      </div>
      <div className="organisation-overview__card-wrapper">
        <AddressOverview
          address={visitorAddress}
          type={t('organisation_view.overview.visitor_address')}
          isFetching={isFetching}
          isAddressPresent={visitorAddress}
          emptyPlaceholderTranslationKey="organisation_view.overview.no_visitor_address_found"
        />
        <ContactOverview organisation={organisation} isFetching={isFetching} />
      </div>
    </>
  );
};

export default OverViewComponent;
