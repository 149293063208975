import { Image, Text, View } from '@react-pdf/renderer';
import StatusBad from 'assets/status-bad.png';
import StatusDisable from 'assets/status-disable.png';
import StatusGood from 'assets/status-good.png';
import StatusNotSubmit from 'assets/status-not-submit.png';
import StatusSubmit from 'assets/status-submit.png';
import styles from 'components/pdf/styles/checklist-styles';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

const ContractualStatus = ({ contractAcknowledgementStatus, contractualStatus, comments }) => {
  const statuses = {
    active: StatusGood,
    approved: StatusGood,
    submitted: StatusSubmit,
    draft: StatusNotSubmit,
    wip: StatusNotSubmit,
    expired: StatusBad,
  };

  const { charta, controlBody, education, goodsDeclaration, product, supplierCertificate } =
    contractualStatus;

  return (
    <>
      <Text style={styles.checklistMarginForty}>
        <Text style={styles.bold}>6. </Text> {t('control_body.survey.form.status')}
      </Text>
      <View style={styles.contractualStatusWrapper}>
        <Text>{t('control_body.survey.shared.contract_status_header.charta')}</Text>
        <View style={styles.contractualStatusLogo}>
          <Image style={styles.statusLogo} src={statuses[charta]} />
        </View>
      </View>
      <View style={styles.contractualStatusHR} />
      <View style={styles.contractualStatusWrapper}>
        <Text>{t('control_body.survey.shared.contract_status_header.education')}</Text>
        <View style={styles.contractualStatusLogo}>
          <Image style={styles.statusLogo} src={statuses[education] || StatusDisable} />
        </View>
      </View>
      <View style={styles.contractualStatusHR} />
      <View style={styles.contractualStatusWrapper}>
        <Text>{t('control_body.survey.shared.contract_status_header.control_body')}</Text>
        <View style={styles.contractualStatusLogo}>
          <Image style={styles.statusLogo} src={statuses[controlBody] || StatusDisable} />
        </View>
      </View>
      <View style={styles.contractualStatusHR} />
      <View style={styles.contractualStatusWrapper}>
        <Text>{t('control_body.survey.shared.contract_status_header.supplier')}</Text>
        <View style={styles.contractualStatusLogo}>
          <Image style={styles.statusLogo} src={statuses[supplierCertificate] || StatusDisable} />
        </View>
      </View>
      <View style={styles.contractualStatusHR} />
      <View style={styles.contractualStatusWrapper}>
        <Text>{t('control_body.survey.shared.contract_status_header.products')}</Text>
        <View style={styles.contractualStatusLogo}>
          <Image style={styles.statusLogo} src={statuses[product] || StatusDisable} />
        </View>
      </View>
      <View style={styles.contractualStatusHR} />
      <View style={styles.contractualStatusWrapper}>
        <Text>{t('control_body.survey.shared.contract_status_header.goods')}</Text>
        <View style={styles.contractualStatusLogo}>
          <Image style={styles.statusLogo} src={statuses[goodsDeclaration] || StatusDisable} />
        </View>
      </View>
      <View style={styles.contractualStatusHR} />

      <View style={styles.checkListFlexSpaceBetween}>
        <Text style={styles.checklistMarginTen}>
          {t('control_body.survey.form.auditor_acknowledgement')}
        </Text>
        <Text style={styles.checklistMarginTen}>
          {contractAcknowledgementStatus
            ? t('shared.action.yes')
            : t('control_body.survey.shared.no')}
        </Text>
      </View>
      {comments.contractualStatus && (
        <View style={styles.baseComment}>
          <Text>
            {t('control_body.survey.form.comment_from_auditor')} {comments.contractualStatus}
          </Text>
        </View>
      )}
    </>
  );
};

ContractualStatus.propTypes = {
  contractAcknowledgementStatus: PropTypes.bool.isRequired,
  contractualStatus: PropTypes.shape().isRequired,
  comments: PropTypes.shape().isRequired,
};

export default ContractualStatus;
