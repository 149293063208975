import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from 'components/base-components/Typography';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { ORGANISATIONS_STATUS } from 'utils/statuses';
import { useGetOrganizationsQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import Card from 'components/base-components/Card';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import { Link } from 'react-router-dom';
import OrganizationBasic from './organisation-basic.component';

const RecentOrganizations = () => {
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();
  const { data: currentUser = {} } = useGetCurrentUserQuery();
  const organizationSearchParams = {
    status: ORGANISATIONS_STATUS.active,
    per_page: 9,
    'except_organization_ids[]': currentUser?.assignments?.map(
      assignment => assignment.organizationId,
    ),
  };
  const { data } = useGetOrganizationsQuery(organizationSearchParams);
  const organizations = data ? data.organizations : [];

  return (
    <Card className="dashboard__recent-organisations">
      <Header level="6" weight="boldest">
        {t('dashboard.recent_organisations')}
      </Header>
      {organizations.length > 0 ? (
        <Fragment>
          <hr />
          <div className="dashboard__recent-organisations-attributes">
            <span className="dashboard--boldest-font">{t('shared.name')}</span>
            <span className="dashboard--boldest-font">{t('shared.status')}</span>
          </div>
          {organizations.map(organization => (
            <OrganizationBasic
              organizationId={organization.id}
              fetchedOrganisation={organization}
            />
          ))}
          <hr />
        </Fragment>
      ) : (
        <EmptyContentPlaceholder
          text={t('organisation.user_view.table_head.no_organisations')}
          iconName="organization"
          showCard={false}
        />
      )}
      {data && parseInt(data.pagination.totalPages, 10) > 1 && (
        <Link to-={navigationLinks.organisationsPage()}>
          <div className="dashboard__tasks-see-all">{t('dashboard.see_organisation')}</div>
        </Link>
      )}
    </Card>
  );
};

export default RecentOrganizations;
