import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { PRODUCER } from 'utils/organisation-industries';
import Card from 'components/base-components/Card';
import { dateMonthYear } from 'utils/date-time-formatter';
import ContentLoaderPlaceholder from 'components/base-components/ContentLoader';
import OrganizationName from 'components/organisations/organization-name';
import UserName from 'components/users/user-name.component';
import OverviewContent from './shared/overview-content.component';

const OverviewHeader = ({ isFetching, organisation, owners }) => {
  const isProducer = organisation?.industry === PRODUCER;

  return (
    <Card className="organisation-overview__gastronomy-card">
      {isFetching ? (
        <ContentLoaderPlaceholder numberOfLines={4} showBackground={false} />
      ) : (
        <div className="organisation-overview__gastronomy-header">
          <OverviewContent
            className="organisation-overview__gastronomy-header-content"
            label={t('organisation.bio_id')}
            value={organisation?.bioId}
            noMargin={true}
          />
          <OverviewContent
            className="organisation-overview__gastronomy-header-content"
            label={t('organisation_type.uid')}
            value={organisation?.uid}
            noMargin={true}
          />
          {isProducer && (
            <OverviewContent
              className="organisation-overview__gastronomy-header-content"
              label={t('organisation_view.overview.kt_id_b')}
              value={organisation?.ktIdB}
              noMargin={true}
            />
          )}
          <OverviewContent
            className="organisation-overview__gastronomy-header-content"
            label={t('organisation_view.overview.first_legitimation')}
            value={
              organisation?.firstLegitimizedAt
                ? dateMonthYear(organisation?.firstLegitimizedAt)
                : null
            }
            noMargin={true}
          />
          <OverviewContent
            className="organisation-overview__gastronomy-header-content"
            label={t('organisation_view.overview.registered_name')}
            value={organisation?.name}
            noMargin={true}
          />
          <OverviewContent
            className="organisation-overview__gastronomy-header-content"
            label={t('organisation_view.overview.industry')}
            value={organisation?.industry && t(`organisation.industry.${organisation.industry}`)}
            noMargin={true}
          />
          <div className="organisation-overview__gastronomy-header-content">
            <span>{t('organisation_view.overview.owner')}</span>
            {owners.length > 0 ? (
              owners.map((owner, index) => (
                <span className="organisation-overview__content-value">
                  <UserName userID={owner.userId} />
                  {index !== owners.length - 1 ? ', ' : ''}
                </span>
              ))
            ) : (
              <span className="organisation-overview__content-value--disabled">
                {t('common.not_applicable')}
              </span>
            )}
          </div>
          <div className="organisation-overview__gastronomy-header-content">
            <span>{t('organisation_view.overview.parent_organisation')}</span>
            {organisation?.parentId ? (
              <span className="organisation-overview__text organisation-overview__text-link">
                <OrganizationName organizationId={organisation?.parentId} />
              </span>
            ) : (
              <span className="organisation-overview__content-value--disabled">
                {t('common.not_applicable')}
              </span>
            )}
          </div>
          <div className="organisation-overview__gastronomy-header-content">
            <span>{t('organisation_view.overview.education_user')}</span>
            {organisation?.educationAssigneeUserId ? (
              <span className="organisation-overview__text organisation-overview__text-link">
                <UserName userID={organisation?.educationAssigneeUserId} />
              </span>
            ) : (
              <span className="organisation-overview__content-value--disabled">
                {t('common.not_applicable')}
              </span>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

OverviewHeader.defaultProps = {
  isFetching: true,
  organisation: {},
  owners: [],
};

OverviewHeader.propTypes = {
  isFetching: PropTypes.bool,
  organisation: PropTypes.shape(),
  owners: PropTypes.arrayOf(PropTypes.shape()),
};

export default OverviewHeader;
