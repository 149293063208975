import { t } from 'i18next';
import BreadcrumbOrganizationName from './breadcrumb-organization';
import BreadcrumbUserName from './breadcrumb-user';

const breadcrumbRoutes = [
  { path: '/', breadcrumb: t('sidenav.dashboard') },
  { path: '/users', breadcrumb: t('organisation_view.tabs.user') },
  { path: '/users/invite', breadcrumb: t('topnav.user.invitation') },
  { path: '/users/invite/:id', breadcrumb: null },
  { path: '/users/:id', breadcrumb: BreadcrumbUserName },
  { path: '/users/:userId/edit', breadcrumb: t('topnav.user.edit') },
  { path: '/organisations', breadcrumb: t('common.organisations') },
  { path: '/organisations/new', breadcrumb: t('topnav.organisations.create') },
  { path: '/organisations/:id', breadcrumb: BreadcrumbOrganizationName },
  { path: '/sub-organisations/:id', breadcrumb: BreadcrumbOrganizationName },
  { path: '/organisations/:id/edit', breadcrumb: t('topnav.organisations.edit') },
  { path: '/organisations/:id/view', breadcrumb: t('topnav.organisations.details') },
  { path: '/invoices', breadcrumb: t('sidenav.invoices') },
  {
    path: '/organisations/:id/control-body-surveys/:surveyId/perform-control',
    breadcrumb: t('topnav.organisations.perform_control'),
  },
  { path: '/tasks', breadcrumb: t('sidenav.task_management') },
  { path: '/tasks/:id', breadcrumb: t('topnav.task.details') },
  { path: '/tasks/edit', breadcrumb: t('topnav.task.edit') },
  { path: '/tasks/edit/:id', breadcrumb: null },
  { path: '/tasks/create', breadcrumb: t('tasks.create_task') },
  { path: '/messages', breadcrumb: t('topnav.messages.list') },
  { path: '/messages/create', breadcrumb: t('topnav.messages.create') },
  { path: '/messages/edit', breadcrumb: t('topnav.messages.edit') },
  { path: '/messages/edit/:id', breadcrumb: null },
  {
    path: '/organisations/:id/invoices',
    breadcrumb: t('sidenav.invoices'),
    props: { link: '/organisations/:id/view?section=invoices' },
  },
  { path: '/invoices/:id', breadcrumb: null },
  { path: '/organisations/:id/invoices/:invoiceId', breadcrumb: t('invoice.invoice_details') },
  { path: '/recognition', breadcrumb: t('sidenav.recognition') },
  { path: '/recognition/:date', breadcrumb: t('recognition.list') },
];

export default breadcrumbRoutes;
