import React from 'react';
import { useGetCurrentUserQuery } from 'api/users';
import WelcomePageComponent from 'components/welcomepage/welcome-page.component';
import SidenavComponent from 'components/sidenav/sidenav.component';
import TopnavComponent from 'components/topnav/topnav.component';
import DefaultSpinner from 'components/shared-components/default-spinner';

const withNavigation = WrappedComponent => {
  return props => {
    const {
      data: currentUser,
      isFetching,
      isSuccess: isCurrentUserFetched,
    } = useGetCurrentUserQuery();

    if (isFetching) return <DefaultSpinner />;

    if (isCurrentUserFetched) {
      return (
        <>
          <TopnavComponent user={currentUser} />
          <SidenavComponent currentUser={currentUser} />
          <WrappedComponent {...props} />
        </>
      );
    }

    return (
      <WelcomePageComponent showLogoOnly={true}>
        <WrappedComponent {...props} />
      </WelcomePageComponent>
    );
  };
};

export default withNavigation;
