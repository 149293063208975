import { Provider, ErrorBoundary } from '@rollbar/react';
import ShowErrorPage from 'components/error-page';
import rollbarInstance from 'utils/rollbar-instance';
import RoutesComponent from './components/routes/routes.component';
import './App.scss';

function App() {
  return (
    <>
      <div className="app__main">
        <Provider instance={rollbarInstance}>
          <ErrorBoundary fallbackUI={ShowErrorPage}>
            <RoutesComponent />
          </ErrorBoundary>
        </Provider>
      </div>
    </>
  );
}

export default App;
