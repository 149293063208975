import Rollbar from 'rollbar';

const DEFAULT_ENVIRONMENT = 'development';

const rollbarConfig = {
  environment: process.env.REACT_APP_ENV || DEFAULT_ENVIRONMENT,
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
};

rollbarConfig.enabled = rollbarConfig.environment !== DEFAULT_ENVIRONMENT;

const rollbarInstance = new Rollbar(rollbarConfig);

export default rollbarInstance;
